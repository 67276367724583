@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(0deg, #F8F8F8 59.29%, #FFFFFF 84.77%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Dosis Medium";
  font-weight: normal;
  src: url("assets/fonts/dosis/Dosis-Medium.ttf");
}

@font-face {
  font-family: "Dosis Regular";
  font-weight: normal;
  src: url("assets/fonts/dosis/Dosis-Regular.ttf");
}

@font-face {
  font-family: "Dosis Bold";
  font-weight: bold;
  src: url("assets/fonts/dosis/Dosis-Bold.ttf");
}

@font-face {
  font-family: "SFPro Medium";
  font-weight: normal;
  src: url("assets/fonts/sfpro/SFPRODISPLAYMEDIUM.OTF");
}

@font-face {
  font-family: "SFPro Regular";
  font-weight: normal;
  src: url("assets/fonts/sfpro/SFPRODISPLAYREGULAR.OTF");
}

@font-face {
  font-family: "SFPro Bold";
  font-weight: bold;
  src: url("assets/fonts/sfpro/SFPRODISPLAYBOLD.OTF");
}

.curve-radius {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

p {
  letter-spacing: 0.025em;
}